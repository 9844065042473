import React from "react";
import { SlSocialLinkedin } from "react-icons/sl";
import { GoMail } from "react-icons/go";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation();
  return (
    <div className="hidden shadow-md 2xl:flex flex-col py-4 gap-2  bg-theme-gray rounded-sm fixed z-50 right-0 top-1/3 p-2">
      <div className="overflow-hidden flex items-center justify-center h-60 w-12">
        <p
          className="text-lg font-semibold rotate-90 whitespace-nowrap"
          style={{
            backgroundImage: "linear-gradient(#fdc302, #fdc302)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom left",
            backgroundSize: "100% 30%",
          }}
        >
          {t("sidebar")}
        </p>
      </div>

      <ul className="flex flex-col gap-4 cursor-none items-center justify-center text-theme-dark ">
        <li className="hover:scale-110 duration-200 ease-in-out ">
          <a
            className="cursor-none"
            href="mailto:info@beefybee.com"
            rel="noopener noreferrer"
            aria-label="Email"
          >
            <GoMail size={28} />
          </a>
        </li>
        <li className="hover:scale-110 duration-200 ease-in-out ">
          <a
            className="cursor-none"
            target="_blank"
            href="https://www.linkedin.com/company/beefybee/about/"
            rel="noopener noreferrer"
            aria-label="Linkedin"
          >
            <SlSocialLinkedin size={28} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;

import React, { useEffect, useState } from "react";

const HexagonCursor = () => {
  const [position, setPosition] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });
  const [cursorPosition, setCursorPosition] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });
  const [size, setSize] = useState(40); // 20% smaller than original 50px
  const [hasMouse, setHasMouse] = useState(false);

  useEffect(() => {
    const updateCursorPosition = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseEnter = () => {
      setSize(50); // Original size
    };

    const handleMouseLeave = () => {
      setSize(40); // Smaller size
    };

    // Check if the device has a fine pointer (mouse)
    const handlePointerChange = (e) => {
      setHasMouse(e.matches);
    };

    const mouseMediaQuery = window.matchMedia("(pointer: fine)");
    setHasMouse(mouseMediaQuery.matches); // Initial check
    mouseMediaQuery.addListener(handlePointerChange);

    let animationFrameId;
    if (hasMouse) {
      window.addEventListener("mousemove", updateCursorPosition);
      document.querySelectorAll('a, button, [role="button"]').forEach((el) => {
        el.addEventListener("mouseenter", handleMouseEnter);
        el.addEventListener("mouseleave", handleMouseLeave);
      });

      const followCursor = () => {
        setPosition((prevPosition) => {
          const dx = cursorPosition.x - prevPosition.x;
          const dy = cursorPosition.y - prevPosition.y;
          return {
            x: prevPosition.x + dx,
            y: prevPosition.y + dy,
          };
        });
        animationFrameId = requestAnimationFrame(followCursor);
      };

      followCursor();
    }

    return () => {
      window.removeEventListener("mousemove", updateCursorPosition);
      document.querySelectorAll('a, button, [role="button"]').forEach((el) => {
        el.removeEventListener("mouseenter", handleMouseEnter);
        el.removeEventListener("mouseleave", handleMouseLeave);
      });
      cancelAnimationFrame(animationFrameId);
      mouseMediaQuery.removeListener(handlePointerChange);
    };
  }, [cursorPosition, hasMouse]);

  if (!hasMouse) {
    return null; // Do not render the component on touch devices
  }

  return (
    <div
      className="hexagon-cursor opacity-90"
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        width: `${size}px`,
        height: `${size}px`,
        pointerEvents: "none", // <- This line ensures that the custom cursor does not block interactions
      }}
    >
      <svg
        className="rotate-90"
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 256 256"
        id="Flat"
      >
        <path d="M128,234.80127a12.00322,12.00322,0,0,1-5.90466-1.54395l-84-47.47827A12.01881,12.01881,0,0,1,32,175.33228V80.66772A12.019,12.019,0,0,1,38.09521,70.221l84.00013-47.47827a12.06282,12.06282,0,0,1,11.80932,0l84,47.47827A12.01881,12.01881,0,0,1,224,80.66772v94.66456a12.019,12.019,0,0,1-6.09521,10.44677l-84.00013,47.47827A12.00322,12.00322,0,0,1,128,234.80127Zm0-205.60889a4.00152,4.00152,0,0,0-1.96814.51465l-84,47.47827A4.00672,4.00672,0,0,0,40,80.66772v94.66456a4.00658,4.00658,0,0,0,2.032,3.48242L126.03186,226.293a4.0215,4.0215,0,0,0,3.93628,0l84-47.47827A4.00672,4.00672,0,0,0,216,175.33228V80.66772a4.00658,4.00658,0,0,0-2.032-3.48242L129.96814,29.707A4.00152,4.00152,0,0,0,128,29.19238Z" />
      </svg>
    </div>
  );
};

export default HexagonCursor;

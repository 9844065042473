import React from "react";
import ChartComponent from "./Charts";
import { useTranslation } from "react-i18next";
import { TfiWrite } from "react-icons/tfi";
import { TbPointerSearch } from "react-icons/tb";
import { VscCode } from "react-icons/vsc";
import {
  PiPlugsConnectedLight,
  PiLinkedinLogo,
  PiListHeart,
} from "react-icons/pi";

const WhyServices = () => {
  const { t } = useTranslation();

  const services = [
    {
      title: t("b2bContentWritingTitle"),
      summary: t("benefitStatsContentWriting"),
      details: t("benefitStatsContentWriting"),
      icon: <TfiWrite className="h-7 w-7" />,
      chartData: {
        datasets: [
          {
            data: [60, 40],
            backgroundColor: ["rgb(252, 201, 32)", "#ffffff"],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      title: t("b2bLeadGenerationTitle"),
      summary: t("benefitStatsLeadGeneration"),
      details: t("benefitStatsLeadGeneration"),
      icon: <PiPlugsConnectedLight className="h-8 w-8" />,
      chartData: {
        datasets: [
          {
            data: [85, 15],
            backgroundColor: ["rgb(252, 201, 32)", "#ffffff"],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      title: t("linkedinGrowthTitle"),
      summary: t("benefitStatsLinkedinGrowth"),
      details: t("benefitStatsLinkedinGrowth"),
      icon: <PiLinkedinLogo className="h-8 w-8" />,
      chartData: {
        datasets: [
          {
            data: [75, 25],
            backgroundColor: ["rgb(252, 201, 32)", "#ffffff"],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      title: t("listBuildingTitle"),
      summary: t("benefitStatsListBuilding"),
      details: t("benefitStatsListBuilding"),
      icon: <PiListHeart className="h-8 w-8" />,
      chartData: {
        datasets: [
          {
            data: [30, 70],
            backgroundColor: ["rgb(252, 201, 32)", "#ffffff"],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      title: t("seaSeoTitle"),
      summary: t("benefitStatsSEO"),
      details: t("benefitStatsSEO"),
      icon: <TbPointerSearch className="h-8 w-8" />,
      chartData: {
        datasets: [
          {
            data: [93, 7],
            backgroundColor: ["rgb(252, 201, 32)", "#ffffff"],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      title: t("websiteCreationTitle"),
      summary: t("benefitStatsWebsiteShort"),
      details: t("benefitStatsWebsite"),
      icon: <VscCode className="h-8 w-8 " />,
      chartData: {
        datasets: [
          {
            data: [71, 29],
            backgroundColor: ["rgb(252, 201, 32)", "#ffffff"],
            hoverOffset: 4,
          },
        ],
      },
    },
  ];

  return (
    <section
      className="w-full bg-theme-gray text-theme-black relative py-8 lg:py-16 px-4"
      id="benefits"
    >
      <div className="text-theme-gray absolute bottom-0 z-10 right-1/4">
        <svg
          className="rotate-180 -mb-12"
          width="70"
          height="70"
          viewBox="0 0 15 15"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path d="M7.538 2c-.294 0-.488.177-.615.385l-5.846 9.538C1 12 1 12.153 1 12.308c0 .538.385.692.692.692h11.616c.384 0 .692-.154.692-.692 0-.154 0-.231-.077-.385l-5.77-9.538C8.029 2.177 7.789 2 7.539 2z" />
        </svg>
      </div>
      <div className="max-w-7xl mx-auto px-2.5 flex flex-col gap-6 lg:gap-12">
        <p className="merri py-2 text-center md:text-right text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
          {t("whyBenefit")}
        </p>
      
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12">
          {services.map((service, index) => (
            <div key={index} className="flex flex-col gap-4 p-4">
              <div className="flex gap-2 justify-center">
                {service.icon}
                <h3 className="merri text-center text-lg lg:text-xl xl:text-2xl">
                  {service.title}
                </h3>
              </div>
              {/* Chart Component */}
              <div className="w-32 h-32 mx-auto">
                <ChartComponent data={service.chartData} />
              </div>
              {/* Details */}
              <div className="text-lg lg:text-xl">
                {service.details}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyServices;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi) // To load translations from the server
  .use(LanguageDetector) // To detect the user's language
  .use(initReactI18next) // To pass i18n instance to React
  .init({
    supportedLngs: ["en", "uk"],
    fallbackLng: "uk", // Default language is Ukrainian
    detection: {
      order: ["localStorage", "navigator"], // First check local storage, then browser language
      caches: ["localStorage"], // Cache the language in local storage
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path to translation files
    },
    react: {
      useSuspense: false, // If you are not using React Suspense
    },
  });

export default i18n;

import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-scroll";
import { MdOutlineSwitchAccessShortcut } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const navRef = useRef();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Initialize language from localStorage if available
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    } else {
      // Default to Ukrainian if no language is saved
      i18n.changeLanguage("uk");
    }
  }, [i18n]);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNav(false);
    }
  };

  useEffect(() => {
    if (nav) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [nav]);

  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Save the user's choice in localStorage
  };

  return (
    <div className="flex shadow-md fixed top-0 z-50 bg-theme-gray backdrop-blur-lg w-full px-4">
      <nav className="justify-between w-full flex font-medium text-lg items-center h-24 max-w-7xl mx-auto">
        <Link
          to="hero"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className="flex gap-2 flex-row items-center "
        >
          <p
            className="merri w-full font-normal italic text-3xl text-theme-black flex items-center gap-1"
            style={{
              backgroundImage: "linear-gradient(#fdc302, #fdc302)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom left",
              backgroundSize: "100% 30%",
            }}
          >
            <span>Beefy</span>
            <span className="font-bold">Bee</span>
          </p>
        </Link>
        <ul className="hidden md:flex items-center justify-end gap-4 lg:gap-8 basis-1/3">
          <li className="group relative hover:scale-110 duration-200 ease-in-out tracking-wide  whitespace-nowrap">
            <Link to="services" spy={true} smooth={true} duration={500}>
              {t("services")}
            </Link>
          </li>
          <li className="group relative hover:scale-110 duration-200 ease-in-out tracking-wide  whitespace-nowrap">
            <Link to="benefits" spy={true} smooth={true} duration={500}>
              {t("benefits")}
            </Link>
          </li>
          <li className="group relative hover:scale-110 duration-200 ease-in-out tracking-wide  whitespace-nowrap">
            <Link to="caseStudy" spy={true} smooth={true} duration={500}>
              {t("caseStudyHeadline")}
            </Link>
          </li>
          <li className="group relative hover:scale-110 duration-200 ease-in-out tracking-wide  whitespace-nowrap">
            <Link to="contact" spy={true} smooth={true} duration={500}>
              {t("contact")}
            </Link>
          </li>
          <li className="flex items-center justify-between group w-[69px]">
            <MdOutlineSwitchAccessShortcut className="group-hover:text-theme-black text-theme-gray ease-in-out duration-200" />
            {i18n.language === "uk" ? (
              <button
                className="font-semibold cursor-pointer"
                onClick={() => switchLanguage("en")}
              >
                EN
              </button>
            ) : (
              <button
                className="font-semibold cursor-pointer"
                onClick={() => switchLanguage("uk")}
              >
                UA
              </button>
            )}
            <MdOutlineSwitchAccessShortcut className="group-hover:text-theme-black text-theme-gray ease-in-out duration-200 rotate-180" />
          </li>
        </ul>
        <div onClick={handleNav} className="block md:hidden z-50">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <ul
          ref={navRef}
          className={
            nav
              ? "fixed left-0 top-24 w-full h-64 bg-theme-gray/90 backdrop-blur-lg flex flex-col justify-center items-center text-xl gap-4 py-12 px-4 ease-in-out duration-500"
              : "ease-in-out duration-500 fixed top-24 bg-theme-gray/90 backdrop-blur-lg flex flex-col justify-center items-center text-lg gap-4 py-12 px-4 right-[-100%]"
          }
        >
          <li className="group relative duration-200 ease-in-out tracking-wide ">
            <Link
              to="about"
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => setNav(false)}
            >
              {t("about")}
            </Link>
          </li>
          <li className="group relative duration-200 ease-in-out tracking-wide ">
            <Link
              to="services"
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => setNav(false)}
            >
              {t("services")}
            </Link>
          </li>
          <li className="group relative duration-200 ease-in-out tracking-wide ">
            <Link
              to="benefits"
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => setNav(false)}
            >
              {t("benefits")}
            </Link>
          </li>
          <li className="group relative hover:scale-110 duration-200 ease-in-out tracking-wide  whitespace-nowrap">
            <Link to="caseStudy" spy={true} smooth={true} duration={500}>
              {t("caseStudyHeadline")}
            </Link>
          </li>
          <li className="group relative duration-200 ease-in-out tracking-wide ">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => setNav(false)}
            >
              {t("contact")}
            </Link>
          </li>
          <li className="flex items-center">
            {i18n.language === "uk" ? (
              <button
                className="font-medium"
                onClick={() => switchLanguage("en")}
              >
                EN
              </button>
            ) : (
              <button
                className="font-medium px-2 py-1"
                onClick={() => switchLanguage("uk")}
              >
                UA
              </button>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;

import React from "react";
import Services from "./components/Services";
import WhyServices from "./components/WhyServices";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Form from "./components/Form";
import CustomCursor from "./components/CustomCursor";
import CaseStudies from "./components/CaseStudies";

function App() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <CustomCursor />
      <Hero />
      <Services />
      <WhyServices />
      <CaseStudies />
      <Form />
      <Footer />
    </div>
  );
}

export default App;

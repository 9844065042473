import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { TfiWrite } from "react-icons/tfi";
import { TbPointerSearch } from "react-icons/tb";
import { VscCode } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import {
  PiPlugsConnectedLight,
  PiLinkedinLogo,
  PiListHeart,
} from "react-icons/pi";

const SlideContent = ({ title, text, icon }) => {
  return (
    <div className="w-full flex flex-col gap-8 justify-center items-center">
      <h3 className="merri h-[64px] text-lg lg:text-xl xl:text-2xl text-center">
        {title}
      </h3>
      <div
        className="mx-auto h-12 w-14 bg-theme-yellow2 p-2"
        style={{
          clipPath:
            "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
        }}
      >
        {icon}
      </div>
      <p className="lg:text-lg text-justify">{text}</p>
    </div>
  );
};

const Services = () => {
  const { t } = useTranslation();
   const slides = [
    {
      title: t("b2bContentWritingTitle"),
      text: t("b2bContentWritingText"),
      icon: <TfiWrite className="h-7 w-7 m-auto" />,
    },
    {
      title: t("b2bLeadGenerationTitle"),
      text: t("b2bLeadGenerationText"),
      icon: <PiPlugsConnectedLight className="h-8 w-8 m-auto" />,
    },
    {
      title: t("linkedinGrowthTitle"),
      text: t("linkedinGrowthText"),
      icon: <PiLinkedinLogo className="h-8 w-8 m-auto" />,
    },
    {
      title: t("listBuildingTitle"),
      text: t("listBuildingText"),
      icon: <PiListHeart className="h-8 w-8 m-auto" />,
    },
    {
      title: t("seaSeoTitle"),
      text: t("seaSeoText"),
      icon: <TbPointerSearch className="h-8 w-8 m-auto" />,
    },
    {
      title: t("websiteCreationTitle"),
      text: t("websiteCreationText"),
      icon: <VscCode className="h-8 w-8 m-auto" />,
    },
  ];
  
  
  return (
    <section
      className="w-full bg-white text-theme-black relative py-8 lg:py-16 px-4"
      id="services"
    >
      <div className="text-white absolute bottom-0 z-20 left-1/4">
        <svg
          className="rotate-180 -mb-12"
          width="70"
          height="70"
          viewBox="0 0 15 15"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path d="M7.538 2c-.294 0-.488.177-.615.385l-5.846 9.538C1 12 1 12.153 1 12.308c0 .538.385.692.692.692h11.616c.384 0 .692-.154.692-.692 0-.154 0-.231-.077-.385l-5.77-9.538C8.029 2.177 7.789 2 7.539 2z" />
        </svg>
      </div>
      <div className="max-w-7xl mx-auto px-2.5 flex flex-col gap-6 lg:gap-12">
        <div className="w-fit z-30">
          <h2
            className="merri tracking-tight text-center md:text-left text-3xl md:text-4xl lg:text-5xl xl:text-6xl"
            style={{
              backgroundImage: "linear-gradient(#fdc302, #fdc302)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom left",
              backgroundSize: "100% 30%",
            }}
          >
            {t("servicesHeadline")}
          </h2>
        </div>
        <div className="text-lg lg:text-xl  z-30">
         {t("introtext")}
        </div>
        <p className="merri py-2 text-center md:text-right text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
        {t("offer")}:
        </p>
      </div>
      <Swiper
        className="max-w-7xl mx-auto w-full py-8 px-12"
        spaceBetween={50}
        pagination={{ clickable: true }}
        navigation={true}
        loop={true}
        autoplay={{
          delay: 9000, 
          disableOnInteraction: true, 
        }}
        modules={[Pagination, Navigation, Autoplay]}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1280: { slidesPerView: 3 },
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide className="px-4 py-6" key={index}>
            <SlideContent {...slide} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Services;

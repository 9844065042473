import React, { useState, useRef, useEffect } from "react";
import { IoArrowDownCircle } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import MyronenkoImage from "../assets/myronenkoCase.webp";
import SavoyImage from "../assets/savoyCase.webp";

const CaseStudies = () => {
  const { t } = useTranslation();
  const [openItemIndex, setOpenItemIndex] = useState(null);
  const [heights, setHeights] = useState({});
  const contentRefs = useRef([]);

  const cases = [
    {
      title: t("titleMyronenko"),
      summary: t("introMyronenko"),
      details: t("detailedMyronenko"),
      image: MyronenkoImage,
    },
    {
      title: t("titleSavoy"),
      summary: t("introSavoy"),
      details: t("detailedSavoy"),
      image: SavoyImage,
    },
  ];

  const toggleItem = (index) => {
    setOpenItemIndex(openItemIndex === index ? null : index);
  };

  useEffect(() => {
    if (openItemIndex !== null) {
      const contentHeight = contentRefs.current[openItemIndex]?.scrollHeight;
      setHeights((prev) => ({
        ...prev,
        [openItemIndex]: contentHeight,
      }));
    }
  }, [openItemIndex]);

  return (
    <section
      className="w-full bg-white text-theme-black relative py-8 lg:py-16 px-4"
      id="caseStudy"
    >
      <div className="text-white absolute bottom-0 z-10 left-1/4">
        <svg
          className="rotate-180 -mb-12"
          width="70"
          height="70"
          viewBox="0 0 15 15"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path d="M7.538 2c-.294 0-.488.177-.615.385l-5.846 9.538C1 12 1 12.153 1 12.308c0 .538.385.692.692.692h11.616c.384 0 .692-.154.692-.692 0-.154 0-.231-.077-.385l-5.77-9.538C8.029 2.177 7.789 2 7.539 2z" />
        </svg>
      </div>
      <div className="max-w-7xl mx-auto px-2.5 flex flex-col gap-6 lg:gap-12">
        <div className="w-fit z-30">
          <h2
            className="merri tracking-tight text-center md:text-left text-3xl md:text-4xl lg:text-5xl xl:text-6xl"
            style={{
              backgroundImage: "linear-gradient(#fdc302, #fdc302)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom left",
              backgroundSize: "100% 30%",
            }}
          >
            {t("caseStudyHeadline")}
          </h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-12">
          {cases.map((caseItem, index) => {
            const isOpen = openItemIndex === index;
            const contentHeight = isOpen ? heights[index] : 0;

            return (
              <div
                key={index}
                className="flex flex-col gap-2 lg:gap-4"
                onClick={() => toggleItem(index)}
              >
                {/* Title and Toggle Arrow */}
                <div className="flex gap-2 items-start min-h-[64px]">
                  <IoArrowDownCircle
                    className={`text-theme-black opacity-90 w-8 h-8 transition-transform duration-200 md:mt-0.5 ease-in-out ${
                      isOpen ? "rotate-0" : "-rotate-90"
                    }`}
                  />
                  <h3 className="merri text-lg lg:text-xl xl:text-2xl">
                    {caseItem.title}
                  </h3>
                </div>

                {/* Image */}
                <div className="w-full aspect-video overflow-hidden shadow-md rounded-md">
                  <img
                    src={caseItem.image}
                    alt={caseItem.title}
                    className="w-full object-cover"
                  />
                </div>

                {/* Text content with smooth transition */}
                <div
                  ref={(el) => (contentRefs.current[index] = el)}
                  className={`text-lg lg:text-xl transition-all duration-300 ease-in-out overflow-hidden`}
                  style={{
                    maxHeight: `${contentHeight}px`,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: caseItem.details }}
                  />
                </div>

                {/* Show summary when not open */}
                {!isOpen && (
                  <div className="text-lg lg:text-xl">
                    {caseItem.summary}{" "}
                    <span className="text-theme-yellow2">
                      {t("readMoreCase")}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CaseStudies;
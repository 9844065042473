import React from "react";
import { Link } from "react-scroll";
import { SlSocialLinkedin } from "react-icons/sl";
import { GoMail } from "react-icons/go";
import ScrollToTop from "react-scroll-up";
import { SlArrowUp } from "react-icons/sl";

const Footer = () => {
  return (
    <div className="bg-theme-gray z-10 relative">
      <section className="w-full pb-8">
        <div className="max-w-7xl mx-auto py-12 px-4 flex flex-col md:flex-row items-center md:items-start justify-between gap-8 ">
          <div className="flex flex-col gap-2">
            <Link
              to="hero"
              className="w-full mx-auto text-center md:text-start"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              <div className="basis-1/3  merri w-full text-3xl font-semibold playfair text-theme-dark flex items-center justify-center md:justify-start gap-1">
                <Link
                  to="hero"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className="flex gap-2 flex-row items-center "
                >
                  <p
                    className="italic merri w-full font-normal text-5xl text-theme-black flex items-center gap-1"
                    style={{
                      backgroundImage: "linear-gradient(#fdc302, #fdc302)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "bottom left",
                      backgroundSize: "100% 30%",
                    }}
                  >
                    <span>Beefy</span>
                    <span className="font-bold">Bee</span>
                  </p>
                </Link>
              </div>
            </Link>
            <p className="text-theme-dark text-center md:text-start lg:text-lg"></p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-theme-dark text-lg uppercase text-center">
              Contact us
            </p>
            <ul className="flex gap-4 items-center justify-center text-theme-dark basis-1/3">
              <li className="hover:scale-110 duration-150 ease-in-out ">
                <a
                  className="cursor-none"
                  href="mailto:info@beefybee.com"
                  rel="noopener noreferrer"
                  aria-label="Email"
                >
                  <GoMail size={28} />
                </a>
              </li>
              <li className="hover:scale-110 duration-200 ease-in-out ">
                <a
                  className="cursor-none"
                  target="_blank"
                  href="https://www.linkedin.com/company/beefybee/about/"
                  rel="noopener noreferrer"
                  aria-label="Linkedin"
                >
                  <SlSocialLinkedin size={28} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="cols-span-full w-full text-center text-theme-dark mt-8 text-sm">
          © 2024 BeefyBee
        </p>
        <ScrollToTop showUnder={160}>
          <div className="text-theme-dark hover:text-theme-yellow duration-150 ease-in-out flex flex-col">
            <SlArrowUp className="-mt-4" size={27} />
          </div>
        </ScrollToTop>
      </section>
    </div>
  );
};

export default Footer;

import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { name, email, message } = data;

    try {
      // Send the main message
      const response = await emailjs.send(
        "service_yzuyifn", // service id for main message
        "template_pqku4zi", // template id for main message
        {
          user_name: name, // Matches {{user_name}} in the main template
          user_email: email, // Matches {{user_email}} in the main template
          message: message, // Matches {{message}} in the main template
        },
        "Z7fKmKEEW-HY8urbX" // EmailJS Public Key
      );

      // Check if the first email was sent successfully
      if (response.status === 200) {
        alert("Email sent successfully!");

        // Send auto-reply email
        await emailjs.send(
          "service_yzuyifn", // service id for auto-reply (can be the same)
          "template_d07100q", // template id for auto-reply
          {
            user_name: name, // Matches {{user_name}} in the auto-reply template
            user_email: email, // Send the reply to the user's email
          },
          "Z7fKmKEEW-HY8urbX" // EmailJS Public Key
        );
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email.");
    }
  };

  return (
    <section
      className="w-full bg-theme-yellow2 text-theme-black py-12 relative px-4"
      id="contact"
    >
      <div className="text-theme-yellow2 absolute bottom-0 z-20 right-1/4">
        <svg
          className="rotate-180 -mb-12"
          width="70"
          height="70"
          viewBox="0 0 15 15"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path d="M7.538 2c-.294 0-.488.177-.615.385l-5.846 9.538C1 12 1 12.153 1 12.308c0 .538.385.692.692.692h11.616c.384 0 .692-.154.692-.692 0-.154 0-.231-.077-.385l-5.77-9.538C8.029 2.177 7.789 2 7.539 2z" />
        </svg>
      </div>
      <div className="max-w-7xl mx-auto px-2.5 flex flex-col gap-y-4 sm:gap-y-8 lg:gap-y-16">
        <div className="w-fit">
          <h2
            className="merri text-center md:text-left text-3xl md:text-4xl lg:text-5xl xl:text-6xl"
            style={{
              backgroundImage: "linear-gradient(#ffffff, #ffffff)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom left",
              backgroundSize: "100% 30%",
            }}
          >
            {t("contactUs")}
          </h2>
        </div>
        <p className="text-lg lg:text-xl">
        {t("contactText")}
        </p>
        <form
          className="w-full flex flex-col gap-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col md:flex-row gap-6">
            <div className="w-full flex flex-col gap-2">
              <input
                className="w-full rounded-sm p-2"
                placeholder={t("name")}
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className="text-theme-black">{t("This field is required")}</span>
              )}
            </div>
            <div className="w-full flex flex-col gap-2">
              <input
                className="w-full rounded-sm p-2"
                placeholder={t("email")}
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="text-theme-black">{t("This field is required")}</span>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col gap-2">
            <textarea
              className="w-full rounded-sm p-2"
              placeholder={t("message")}
              {...register("message", { required: true })}
            ></textarea>
            {errors.message && (
              <span className="text-theme-black">{t("This field is required")}</span>
            )}
          </div>
          <button
            className="cursor-none px-8 py-4 rounded-sm hover:bg-white/80 bg-white text-lg w-fit text-theme-yellow2 font-bold mx-auto uppercase"
            type="submit"
          >
            {t("send")}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
